<template>
  <el-row>
    <el-col :span="24">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.companyName" placeholder="企业名称" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.status" placeholder="当前运行状态" @change="handleSearch">
            <el-option v-for="(item, index) in enterpriseStatus" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.accountType" placeholder="账号类型" @change="handleSearch">
            <el-option v-for="(item, index) in enterpriseAccountType" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.openPlatform" placeholder="开通平台" @change="handleSearch">
            <el-option v-for="(item, index) in platformType" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">创建企业账号</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="companyName" label="企业名称" width="200"></el-table-column>
        <el-table-column prop="userMobile" label="企业登录账号" width="160"></el-table-column>
        <el-table-column prop="contactName" label="管理员姓名" width="160"></el-table-column>
        <el-table-column prop="contactMobile" label="企业联系方式" width="160"></el-table-column>
        <el-table-column prop="validStartDate" label="账号有效期" width="200">
          <template slot-scope="scope"> {{ scope.row.validStartDate }}-{{ scope.row.validEndDate }} </template>
        </el-table-column>
        <el-table-column prop="accountType" label="账号类型" :formatter="formatAccountType"></el-table-column>
        <el-table-column prop="openPlatform" label="开通平台" :formatter="row => (row.openPlatform === 'HAPPINESS_CHECK' ? '幸福绩效' : '三清宝')"></el-table-column>
        <el-table-column prop="status" label="当前运行状态" :formatter="formatstatus" width="160"></el-table-column>
        <el-table-column prop="state" label="账号状态" :formatter="formatAccountstate"></el-table-column>
        <el-table-column label="操作" fixed="right" width="260">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="edit(scope.row.dataCompanyId)">编辑</el-button>
              <el-button size="small" @click="setState(scope.row.dataCompanyId, 0)" v-if="scope.row.state === 1">禁用</el-button>
              <el-button size="small" @click="setState(scope.row.dataCompanyId, 1)" v-else>启用</el-button>
              <el-button size="small" @click="changeAdmin(scope.row)">更换管理员</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="size"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
      <!--新增编辑-->
      <el-dialog :title="addEditTitle" :visible.sync="addEditDialog" :close-on-click-modal="false" width="590px">
        <el-form :model="addEditForm" label-width="120px" :rules="addEditFormRules" ref="addEditForm">
          <el-form-item label="企业名称" prop="companyName">
            <el-input v-model.trim="addEditForm.companyName" placeholder="请输入企业名称" maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="企业登录账号" prop="userMobile">
            <el-input v-model.trim="addEditForm.userMobile" placeholder="请输入企业登录账号"></el-input>
          </el-form-item>
          <el-form-item label="账号类型" prop="accountType">
            <el-select v-model="addEditForm.accountType" placeholder="请选择账号类型">
              <el-option v-for="(item, index) in enterpriseAccountType" :key="index" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开通平台" prop="openPlatform">
            <el-select v-model="addEditForm.openPlatform" :disabled="dredge" placeholder="请选择开通平台">
              <el-option v-for="(item, index) in platformType" :key="index" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号角色" prop="roleId">
            <el-select v-model="addEditForm.roleId" placeholder="请选择">
              <el-option v-for="(item, index) in accountRole" :key="index" :label="item.roleName" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号有效期" prop="startEndDate">
            <el-date-picker
              v-model="addEditForm.startEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div style="margin: 40px 0px;">
            <el-divider>企业联系方式</el-divider>
          </div>
          <el-form-item label="管理员姓名" prop="contactName">
            <el-input v-filteremoji v-model.trim="addEditForm.contactName" placeholder="请输入管理员姓名" maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="企业联系方式" prop="contactMobile">
            <el-input v-model.trim="addEditForm.contactMobile" placeholder="请输入企业联系方式"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addEditDialog = false">取消</el-button>
          <el-button v-if="isAddOrEdit === 1" type="primary" @click.native="addSubmit">确认</el-button>
          <el-button v-if="isAddOrEdit === 2" type="primary" @click.native="editSubmit">确认</el-button>
        </div>
      </el-dialog>
      <!--更换管理员-->
      <el-dialog title="更换管理员" :visible.sync="changeAdminDialog" :close-on-click-modal="false" width="500px">
        <el-form :model="changeAdminForm" label-width="120px" :rules="changeAdminFormRules" ref="changeAdminForm">
          <div style="margin: 40px 0px;">当前企业管理员姓名：{{ changeName }} {{ changeMobile }}</div>
          <el-form-item label="接任者姓名" prop="contactName">
            <el-input v-model.trim="changeAdminForm.contactName" placeholder="请输入接任者姓名" maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="接任者手机号" prop="userMobile">
            <el-input v-model.trim="changeAdminForm.userMobile" placeholder="请输入接任者手机号"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="changeAdminDialog = false">取消</el-button>
          <el-button type="primary" @click.native="changeAdminSubmit">确认</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import { Add, Edit, List, Detail, updateState, Shift, enterpriseRoleList } from '@/api/operate/api_enterprise'
import PagesMixins from '@/mixins/pagesMixins.js'
import _ from 'lodash'
import { enterpriseStatus, enterpriseAccountType, platformType } from '@/config/config'
import { checkMobile } from '@/utils'
let searchForm = {
  companyName: '',
  status: null,
  accountType: null,
  openPlatform: null
}
let addEditForm = {
  companyName: '',
  userMobile: null,
  accountType: 1,
  roleId: 139,
  openPlatform: '',
  validStartDate: '',
  validEndDate: '',
  startEndDate: [defaultTime(0), defaultTime(1)],
  contactMobile: '',
  contactName: ''
}
let changeAdminForm = {
  contactName: '',
  userMobile: ''
}

function defaultTime(t) {
  var date = new Date()
  var y = date.getFullYear() + t
  var m = date.getMonth() + 1
  var d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var nm = m < 10 ? '0' + m : m
  return y + '-' + nm + '-' + d
}

export default {
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      enterpriseStatus,
      enterpriseAccountType,
      platformType,
      listData: [],
      addEditDialog: false, //新增编辑弹框是否显示
      addEditTitle: '',
      isAddOrEdit: null, //1新增2编辑
      dataCompanyId: null,
      addEditFormRules: {
        companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        userMobile: [
          { required: true, message: '请输入企业登录账号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        accountType: [{ required: true, message: '请选择账号类型', trigger: 'change' }],
        openPlatform: [{ required: true, message: '请选择开通平台', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择账号角色', trigger: 'change' }],
        startEndDate: [{ required: true, message: '请选择账号有效期', trigger: 'change' }],
        contactName: [
          { required: true, message: '请输入管理员姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '2-20个字', trigger: 'blur' }
        ],
        contactMobile: [
          { required: true, message: '请输入企业联系方式', trigger: 'blur' },
          { min: 2, max: 50, message: '最多50个字', trigger: 'blur' }
          //{ validator: checkPhone, trigger: 'blur' }
        ]
      },
      addEditForm: _.cloneDeep(addEditForm),
      accountRole: [],
      changeAdminDialog: false,
      changeAdminForm: _.cloneDeep(changeAdminForm),
      changeAdminFormRules: {
        contactName: [
          { required: true, message: '请输入接任者手姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '2-20个字', trigger: 'blur' }
        ],
        userMobile: [
          { required: true, message: '请输入接任者手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      changeName: '',
      changeMobile: '',
      dredge: ''
    }
  },
  mixins: [PagesMixins],
  mounted() {
    this.handleSearch()
    this.getEnterpriseRoleList()
  },
  methods: {
    formatAccountstate(row, column, cellValue) {
      return cellValue ? '启用' : '禁用'
    },
    formatstatus(row, column, cellValue) {
      return this.enterpriseStatus.filter(val => val.value === cellValue).map(cell => cell.label)
    },
    formatAccountType(row, column, cellValue) {
      return this.enterpriseAccountType.filter(val => val.value === cellValue).map(cell => cell.label)
    },
    /**
     * 查询
     */
    handleSearch() {
      this.page = 1
      this.getListData()
    },
    /**
     * 重置
     */
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    /**
     * 获取列表数据
     */
    async getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      let res = await List(params)
      if (res && res.code === '000000') {
        this.total = res.data.total
        this.listData = res.data.list
      } else {
        this.$message.error({ showClose: true, message: res.msg, duration: 2000 })
      }
    },
    /**
     * 获取角色列表
     */
    async getEnterpriseRoleList() {
      let res = await enterpriseRoleList()
      if (res && res.code === '000000') {
        this.accountRole = res.data.list
      }
    },
    /**
     * 新增
     */
    add() {
      this.dredge = false
      this.addEditTitle = '创建企业账号'
      this.addEditForm.accountType = 1 //默认账号类型为购买
      this.isAddOrEdit = 1
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      this.addEditForm = _.cloneDeep(addEditForm)
      this.addEditDialog = true
    },

    addSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = {
            ...this.addEditForm
          }
          params.validStartDate = params.startEndDate[0]
          params.validEndDate = params.startEndDate[1]
          delete params.startEndDate
          Add(params).then(res => {
            if (res && res.code === '000000') {
              this.$message.success({ showClose: true, message: '新增成功', duration: 2000 })
              this.addEditDialog = false
              this.handleReset()
            } else {
              this.$message.error({ showClose: true, message: res.msg || '新增失败', duration: 2000 })
            }
          })
        }
      })
    },
    /**
     * 编辑
     */
    edit(dataCompanyId) {
      this.dredge = true
      this.addEditTitle = '编辑企业账号'
      this.isAddOrEdit = 2
      this.dataCompanyId = dataCompanyId
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      let params = {
        dataCompanyId
      }
      Detail(params).then(res => {
        if (res && res.code === '000000') {
          let data = res.data
          for (let key in this.addEditForm) {
            this.addEditForm[key] = data[key]
          }
          this.addEditForm.startEndDate = [data.validStartDate, data.validEndDate]
          this.addEditDialog = true
        } else {
          this.$message.error({ showClose: true, message: res.msg || '新增失败', duration: 2000 })
        }
      })
    },
    editSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = {
            ...this.addEditForm
          }
          params.validStartDate = params.startEndDate[0]
          params.validEndDate = params.startEndDate[1]
          params.dataCompanyId = this.dataCompanyId
          delete params.startEndDate
          Edit(params).then(res => {
            if (res && res.code === '000000') {
              this.$message.success({ showClose: true, message: '编辑成功', duration: 2000 })
              this.addEditDialog = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: res.msg || '编辑失败', duration: 2000 })
            }
          })
        }
      })
    },
    /**
     * 0-禁用；1-启用；
     */
    setState(id, state) {
      let statusName = state === 1 ? '启用' : '禁用'
      this.$confirm('是否确认' + statusName + '此企业账号？', statusName, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setStateSubmit(id, state)
      })
    },
    setStateSubmit(id, state) {
      let params = {
        dataCompanyId: id,
        state
      }
      updateState(params).then(res => {
        if (res && res.code === '000000') {
          this.$message.success({ showClose: true, message: res.msg || '设置成功', duration: 2000 })
          this.getListData()
        } else {
          this.$message.error({ showClose: true, message: res.msg || '设置失败', duration: 2000 })
        }
      })
    },
    changeAdmin(row) {
      if (this.$refs.changeAdminForm !== undefined) {
        this.$refs.changeAdminForm.resetFields()
      }
      this.dataCompanyId = row.dataCompanyId
      this.changeName = row.contactName
      this.changeMobile = row.userMobile
      this.changeAdminDialog = true
    },
    changeAdminSubmit() {
      this.$refs.changeAdminForm.validate(valid => {
        if (valid) {
          let params = {
            dataCompanyId: this.dataCompanyId,
            ...this.changeAdminForm
          }
          Shift(params).then(res => {
            if (res && res.code === '000000') {
              this.$message.success({ showClose: true, message: res.msg || '更换成功', duration: 2000 })
              this.changeAdminDialog = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: res.msg || '更换失败', duration: 2000 })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
