/**
 * 企业管理
 */
import * as http from '../http'
//列表
export const List = params => http.post('/enterprise/search', params)
//新增
export const Add = params => http.post('/enterprise/add', params)
//编辑
export const Edit = params => http.post('/enterprise/update', params)
//获取详情
export const Detail = params => http.post('/enterprise/edit', params)
//状态变更
export const updateState = params => http.post('/enterprise/state', params)
//企业更换超管
export const Shift = params => http.post('/enterprise/shift', params)

//企业角色列表
export const enterpriseRoleList = params => http.post('/role/enterprise/list', params)
